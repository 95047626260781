<template>
  <img alt="" src="/images/icons/toasts/error.svg" />
</template>

<script>
export default {
  name: 'ErrorToastIcon',
};
</script>

<style scoped></style>
