<template>
  <img alt="" src="/images/icons/toasts/success.svg" />
</template>

<script>
export default {
  name: 'SuccessToastIcon',
};
</script>

<style scoped></style>
