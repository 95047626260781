import { render, staticRenderFns } from "./Tokenomics.vue?vue&type=template&id=1fd09ffe&scoped=true&"
import script from "./Tokenomics.vue?vue&type=script&lang=js&"
export * from "./Tokenomics.vue?vue&type=script&lang=js&"
import style0 from "./Tokenomics.vue?vue&type=style&index=0&id=1fd09ffe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd09ffe",
  null
  
)

export default component.exports