<template>
  <div class="close-icon-wrapper">
    <img src="/images/icons/toasts/close-icon.svg" />
  </div>
</template>

<script>
export default {
  name: 'CloseToastComponent',
};
</script>

<style scoped lang="scss">
.close-icon-wrapper {
  height: 100%;
  img {
    height: 14px;
    width: 14px;
  }
}
</style>
